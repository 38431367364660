





























import Vue from "vue";
import { mapState } from "vuex";
import { dispatch } from "@/store";

export default Vue.extend({
  name: "UserEdit",
  data() {
    return {
      avatar: "",
      nickname: "",
      phone: ""
    };
  },
  computed: {
    ...mapState({ user: "user" }),
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      images: "images"
    })
  },
  methods: {
    onUploaded({ url }: { url: string }) {
      this.avatar = url;
    },
    onSave() {
      if (!this.nickname.trim()) {
        this.$message.info(this.$t("userEdit.required") + "");
        return;
      }
      const mobileRe = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
      if (this.phone.trim() && !mobileRe.test(this.phone.trim())) {
        this.$message.info("" + this.$t("inputError", [this.$t("mobile")]));
        return;
      }
      dispatch
        .userPatch({
          avatar: this.avatar || undefined,
          nickname: this.nickname.trim(),
          bind_phone: this.phone.trim()
        })
        .then(() => {
          this.$message.info(this.$t("userEdit.editSuccess") + "");
          this.$router.back();
        });
    }
  },
  mounted() {
    this.avatar = this.user.avatar;
    this.nickname = this.user.nickname;
    this.phone = this.user.bind_phone;
  },
  watch: {
    user(user) {
      this.avatar = user.avatar;
      this.nickname = user.nickname;
      this.phone = user.bind_phone;
    }
  }
});
